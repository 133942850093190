module.exports = {
  defaultTitle: 'Alex Kramer',
  logo: '../static/thumbnail/AKHeadshot.jpg',
  author: 'Alex Kramer',
  url: 'https://www.alexkramerbanjo.com',
  legalName: 'Alexander Kramer',
  defaultDescription:
    'I’m Alex and I’m a Fullstack, Front-End, and Backend engineer!  I also play Old-Time Music and call square dances.',
  socialLinks: {
    twitter: 'http://www.twitter.com/alexkramerbanjo',
    github: 'https://github.com/alexkramerbanjo',
    linkedin: 'https://www.linkedin.com/in/alexmkramer/',
    instagram: 'https://instagram.com/alexkramerbanjo',
  },
  googleAnalyticsID: '###',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'alexmkramer',
    twitter: '@alexkramerbanjo',
  },
  address: {
    city: 'Brooklyn',
    region: 'NY',
    country: 'USA',
    zipCode: '11238',
  },
  contact: {
    email: 'alexkrameris@gmail.com',
    phone: '646-872-6261',
  },
  foundingDate: '2019',
  recaptcha_key: '6Le4_ZwUAAAAAGvz9MfEP810BGib8WWqh_6OzYb2',
}
